exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-sms-js": () => import("./../../../src/pages/sms.js" /* webpackChunkName: "component---src-pages-sms-js" */),
  "component---src-pages-todo-js": () => import("./../../../src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */)
}

